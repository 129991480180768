import React, {useState} from "react";

import Popover from "@mui/material/Popover";
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export type ShowEquipmentPropsProps = {
    record: any;
}

export const ShowEquipmentProps = ({record}: ShowEquipmentPropsProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // TODO
    const envUrl = record.environment.environmentId === 3 ? 'backdev.snapify.ai' : 'backqa.snapify.ai';

    return (
        <>
            <Tooltip title={`Show Equipment Info`}>
                <IconButton onClick={handleOpen}>
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={record.equipmentId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{padding: '10px', width: '600px', height: '420px'}}>
                    <iframe
                        title={'Equipment Info'}
                        src={`//${envUrl}/#/Equipment/Popover/${record.serial}`}
                        style={{width: '100%', height: '100%', border: 'none'}}
                    />
                </div>
            </Popover>
        </>
    );
}

