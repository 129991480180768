import React, {useState, useRef, useEffect} from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {FirmwarePicker} from "../../../Inputs";
import {FirmwarePickerType} from "../../../../types";

import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";

export type UpsertFirmwareActionProps = {
    record: any;
}

export const UpsertFirmwareAction = ({record}: UpsertFirmwareActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedFirmware, setSelectedFirmware] = useState<FirmwarePickerType | null>(null);
    const currentFirmware = useRef<FirmwarePickerType | null>(null);

    const [upsertDialogIsOpen, setUpsertDialogIsOpen] = useState<boolean>(false);
    const [detachDialogIsOpen, setDetachDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (record?.firmware) {
            currentFirmware.current = {label: record.firmware.notes, value: record.firmware.firmwareId};
        }
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const {mutateAsync: assignFirmware, isLoading: isLoadingAssignFirmware} = useMutation(() => {
        if (!selectedFirmware) {
            return;
        }
        return dataProvider.saPost(`Equipment/${record.equipmentId}/Firmware?FirmwareId=${selectedFirmware?.value}`, {});
    }, {
        onSuccess: data => {
            notify("Firmware assigned successfully", {type: 'success'});
        },
        onError: () => {
            notify("Firmware assignment failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    const {mutateAsync: detachFirmware, isLoading: isLoadingDetachFirmware} = useMutation(() => {
        const firmwareId = record?.firmware?.firmwareId;
        if (!firmwareId) {
            return;
        }
        return dataProvider.saDelete(`Equipment/${record.equipmentId}/Firmware?FirmwareId=${firmwareId}`, {});
    }, {
        onSuccess: data => {
            notify("Firmware detach successfully", {type: 'success'});
        },
        onError: () => {
            notify("Firmware detach failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    // Popover
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelect = (firmware: FirmwarePickerType) => {
        setSelectedFirmware(firmware);
    }

    // Assign Firmware
    const assignFirmwareOp = async () => {
        if (!selectedFirmware) {
            return;
        }
        await assignFirmware();
    }

    const onAssign = async () => {
        if (record.firmware) {
            setUpsertDialogIsOpen(true);
            return;
        }
        await assignFirmwareOp();
        handleClose();
    }

    const handleUpsertDialogOk = async () => {
        setUpsertDialogIsOpen(false);
        await assignFirmwareOp();
        handleClose();
    }

    const handleUpsertDialogClose = () => {
        setUpsertDialogIsOpen(false);
        handleClose();
    }

    // Detach Firmware
    const detachFirmwareOp = async () => {
        await detachFirmware();
    }

    const onDetachFirmware = async () => {
        if (record.firmware) {
            setDetachDialogIsOpen(true);
            return;
        }
        handleClose();
    }

    const handleDetachDialogOk = async () => {
        setDetachDialogIsOpen(false);
        await detachFirmwareOp();
        handleClose();
    }

    const handleDetachDialogClose = () => {
        setDetachDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingAssignFirmware || isLoadingDetachFirmware) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`${!record.firmware ? 'Set' : 'Edit'} Firmware`}>
                <IconButton onClick={handleOpen}>
                    {
                        !record.firmware ? <AddIcon/> : <EditIcon/>
                    }
                </IconButton>
            </Tooltip>
            <Popover
                id={record.equipmentId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 330,
                    height: 120,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <FirmwarePicker
                        value={record.firmware ? {
                            label: record.firmware.notes,
                            value: record.firmware.firmwareId
                        } : null}
                        onSelect={onSelect}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            width: '100%'
                        }}
                    >
                        <Button
                            disabled={!selectedFirmware}
                            variant="outlined"
                            startIcon={<CheckIcon/>}
                            onClick={onAssign}
                        >
                            Save
                        </Button>
                        <Button
                            disabled={!record?.firmware}
                            variant="outlined"
                            startIcon={<DeleteIcon/>}
                            onClick={onDetachFirmware}
                            color={'warning'}
                        >
                            Detach Firmware
                        </Button>
                    </Box>
                </Box>
            </Popover>
            <Dialog
                open={upsertDialogIsOpen}
                onClose={handleUpsertDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Override Firmware?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to override the firmware?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpsertDialogClose}>Cancel</Button>
                    <Button onClick={handleUpsertDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={detachDialogIsOpen}
                onClose={handleDetachDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Detach Firmware?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to detach the firmware from the current equipment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDetachDialogClose}>Cancel</Button>
                    <Button onClick={handleDetachDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

