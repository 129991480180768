import React, {useState} from "react";
import {BooleanField, Datagrid, FunctionField, List, TextField, TextInput, WrapperField} from "react-admin";
import {FirmwarePopoverField} from "../../Popover";
import {SensitiveDataField} from "../../Fields";
import {UpsertFirmwareAction} from "./UpsertFirmwareAction";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import {ActionsField} from "./ActionsField";

const environmentFilters = [
    <TextInput
        label="Name"
        source="Name"
        alwaysOn
        resettable
    />,
];

export const EnvironmentList = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentEnvironmentId, setCurrentEnvironmentId] = useState<number | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, currentEnvironmentId: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentEnvironmentId(currentEnvironmentId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentEnvironmentId(null);
    };

    return (
        <List
            title="Environment"
            queryOptions={{
                meta: {
                    alternativeId: 'environmentId',
                    pagination: true,
                    dataExtractFunction: (data: { payload: any }) => {
                        return data.payload.results;
                    }
                },
                refetchOnMount: true,
            }}
            perPage={25}
            filters={environmentFilters}
        >
            <ListFilterCleaner>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="environmentId"/>
                    <TextField source="name"/>
                    <TextField source="azFnUrl"/>
                    <SensitiveDataField source={"azFnKey"}/>
                    <BooleanField source="isDefault"/>
                    <WrapperField source={'firmware'}>
                        <FirmwarePopoverField
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                            currentResourceId={currentEnvironmentId}
                            resourceIdKey={'environmentId'}
                        />
                        <FunctionField label={"Firmware"} render={(record: any) => (
                            <UpsertFirmwareAction record={record}/>
                        )}/>
                    </WrapperField>
                    <ActionsField />
                </Datagrid>
            </ListFilterCleaner>
        </List>
    );
};
