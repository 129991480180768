import React, {useState, useEffect} from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Switch,
    Tooltip
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";
import Typography from "@mui/material/Typography";

export type UpsertAllowReRegActionProps = {
    record: any;
}

export const UpsertAllowReRegAction = ({record}: UpsertAllowReRegActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<boolean>(record.allowReReg);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setSelectedStatus(record.allowReReg);
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: changeAllowReReg, isLoading: isLoadingChangeAllowReReg } = useMutation(() => {
        if (!selectedStatus) {
            return dataProvider.saDelete(`Equipment/${record.equipmentId}/ReReg`, {});
        }
        return dataProvider.saPost(`Equipment/${record.equipmentId}/ReReg`, {});
    }, {
        onSuccess: data => {
            notify("Allow Re Reg update successfully", { type: 'success' });
        },
        onError: () => {
            notify("Allow Re Reg failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChange = async () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await changeAllowReReg();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingChangeAllowReReg) {
        return <CircularProgress />;
    }

    return (
        <>
            <Tooltip title={`Update Allow Registration`}>
                <IconButton onClick={handleOpen}>
                    <EditIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={record.equipmentId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 150,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{p: 2}}>Allow Re Registration</Typography>
                    <Switch checked={selectedStatus} value={selectedStatus} onChange={() => setSelectedStatus(!selectedStatus)}/>
                    <Button
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onChange}>
                        Save
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change Allow Re Registration?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to change the allow re registration setting?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

