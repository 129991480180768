import React, {useCallback, useState} from 'react';
import {useGetList} from "react-admin";

import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {EnvironmentPickerType, EquipmentPickerType} from "../../types";

type EquipmentOptionType = EquipmentPickerType | null;

type EquipmentPickerProps = {
    userId?: number;
    value: EquipmentOptionType;
    onSelect: (value: EquipmentPickerType) => void;
    disabled?: boolean;
}

export const EquipmentPicker = (props: EquipmentPickerProps) => {
    const {value: val, onSelect, userId, disabled} = props;

    const [value, setValue] = useState<EquipmentOptionType>(val);

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload.results;
    }, []);

    const filters = userId ? {'UserId': userId} : {};

    const { data, isLoading, error } = useGetList(
        'equipment',
        {
            filter: {'Limit': -1, 'Offset': 0, 'OnlyUnassigned': false, ...filters},
            meta: {
                pagination: false,
                alternativeId: 'equipmentId',
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
            enabled: !disabled,
        }
    );

    if (error) {
        return <p>ERROR</p>;
    }

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: 300, height: 50, display: 'flex'}}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ width: 300, height: 50, display: 'flex' }}>
            <Autocomplete
                disabled={disabled}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as EnvironmentPickerType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="environment-picker"
                options={data?.map(op => ({label: op.serial, value: op.equipmentId})) ?? [] as EquipmentOptionType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props}>{option?.label}</li>}
                sx={{ width: 300 }}
                renderInput={(params) => (
                    <TextField {...params} label="Search Equipment" />
                )}
            />
        </Box>
    );
}
