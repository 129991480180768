import React from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {FunctionField} from "react-admin";

type EnvironmentPopoverFieldProps = {
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>, currentEnvironmentId: number) => void;
    handleClose: () => void;
    anchorEl: HTMLButtonElement | null;
    currentResourceId: number | null;
    resourceIdKey: string;
}

export const EnvironmentPopoverField = (props: EnvironmentPopoverFieldProps) => {
    const {
        handleOpen,
        handleClose,
        anchorEl,
        currentResourceId,
        resourceIdKey
    } = props;

    return (
        <FunctionField label={"Environment"} render={(record: any) => {
            if (!record.environment) {
                return null;
            }
            return (
                <>
                    <Button
                        onClick={(e) => handleOpen(e, record[resourceIdKey])}>{record.environment.name}</Button>
                    <Popover
                        id={record[resourceIdKey]}
                        open={currentResourceId === record[resourceIdKey]}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 1}}><b>environment id:</b> {record.environment?.environmentId}</Typography>
                        <Typography sx={{p: 1}}><b>name:</b> {record.environment?.name}</Typography>
                        <Typography sx={{p: 1}}><b>azure fn url:</b> {record.environment?.azFnUrl}</Typography>
                        <Typography sx={{p: 1}}><b>azure fn key:</b> {record.environment?.azFnKey}</Typography>
                        <Typography sx={{p: 1}}><b>is default:</b> {record.environment?.isDefault ? 'Yes' : 'No'}</Typography>
                    </Popover>
                </>
            );
        }}/>
    );
}
