import React from "react";

import {FunctionField} from "react-admin";
import {DeactivateEquipment} from "./DeactivateEquipment";
import {ActivateEquipment} from "./ActivateEquipment";

export const ActivateDeactivateActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: any) => {
                    return (
                        <>
                            {
                                record.isActive ? <DeactivateEquipment record={record}/> :
                                    <ActivateEquipment record={record}/>
                            }
                        </>
                    );
                }
            }
        />
    );
};
