import React from "react";

import NetworkLockedIcon from '@mui/icons-material/NetworkLocked';
import {IconButton, Tooltip} from "@mui/material";

export type ConnectToVpnActionProps = {
    record: any;
}

export const ConnectToBox = ({record}: ConnectToVpnActionProps) => {

    const handleClick = (e: any) => {
        const element = document.createElement("a");
        const file = new Blob([`"C:\\Program Files\\PuTTY\\putty.exe" -ssh ${record.username}@${record.regIp} -pw ${record.password}`], {
            type: "application/x-bat"
        });
        element.href = URL.createObjectURL(file);
        element.download = `connect-to-box-${record.id}.bat`;
        document.body.appendChild(element);
        element.click();
    }

    if (!record?.regIp) {
        return null;
    }

    return (
        <Tooltip title="Connect To Box">
            <IconButton onClick={(e) => handleClick(e)}>
                <NetworkLockedIcon/>
            </IconButton>
        </Tooltip>
    );
}
