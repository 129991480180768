import React from "react";

import EditIcon from '@mui/icons-material/Edit';
import {IconButton, Tooltip} from "@mui/material";
import {useRedirect} from "react-admin";

export type EditEventActionProps = {
    record: any;
}

export const EditEvent = ({record}: EditEventActionProps) => {
    const redirect = useRedirect();

    const handleClick = () => {
        redirect('edit', 'Event', record.eventId);
    }

    return (
        <>
            <Tooltip title="Edit Event">
                {
                    <IconButton onClick={handleClick}>
                        <EditIcon/>
                    </IconButton>
                }
            </Tooltip>
        </>
    );
}
