import React, {useState} from "react";

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {useMutation, useQueryClient} from "react-query";
import {useNotify} from "react-admin";
import {useDataProvider} from "ra-core";

export type DeactivateEquipmentActionProps = {
    record: any;
}

export const DeactivateEquipment = ({record}: DeactivateEquipmentActionProps) => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const {mutateAsync: deactivateEquipment, isLoading: isLoadingDeactivateEquipment} = useMutation(() => {
        const equipmentId = record.equipmentId;
        if (!equipmentId) {
            return;
        }
        return dataProvider.saDelete(`Equipment/${equipmentId}/Deactivate`, {});
    }, {
        onSuccess: data => {
            notify("Equipment deactivated successfully", {type: 'success'});
        },
        onError: () => {
            notify("Equipment deactivate failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    const handleClick = () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await deactivateEquipment();
        return;
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        return;
    }

    return (
        <>
            <Tooltip title="Deactivate Equipment">
                {
                    isLoadingDeactivateEquipment ? <CircularProgress/> : (
                        <IconButton onClick={handleClick}>
                            <RemoveCircleOutlineIcon/>
                        </IconButton>
                    )
                }
            </Tooltip>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deactivate Equipment?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to deactivate this equipment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
