import React, {createContext, useContext, useEffect, useState} from 'react';
import {loginRequest, msalInstance} from "./authProvider";

type TokenType = string | null | undefined;

type AuthContextType = {
    accessToken: TokenType;
}

const TokenContext = createContext<AuthContextType>({
    accessToken: null,
});

type TokenProviderProps = {
    children: React.ReactNode;
}

export const TokenProvider = (props: TokenProviderProps) => {
    const {children} = props;

    const [token, setToken] = useState<string | null | undefined>(null);

    useEffect(() => {
        console.log('TokenProvider: useEffect - acquiring token');
        msalInstance.acquireTokenSilent(loginRequest).then((payload) => {
            setToken(payload.accessToken);
        }).catch(() => {
            msalInstance.loginRedirect(loginRequest);
        });
    }, []);

    return (
        <TokenContext.Provider value={{
            accessToken: token,
        }}>
            {children}
        </TokenContext.Provider>
    );
}


export const useToken = () => {
    const {accessToken} = useContext(TokenContext);
    return {accessToken};
}
