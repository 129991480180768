import React from "react";

import EditIcon from '@mui/icons-material/Edit';
import {IconButton, Tooltip} from "@mui/material";
import {useRedirect} from "react-admin";

export type EditEnvironmentActionProps = {
    record: any;
}

export const EditEnvironment = ({record}: EditEnvironmentActionProps) => {
    const redirect = useRedirect();

    const handleClick = () => {
        redirect('edit', 'Environment', record.environmentId);
    }

    return (
        <>
            <Tooltip title="Edit Environment">
                {
                    <IconButton onClick={handleClick}>
                        <EditIcon/>
                    </IconButton>
                }
            </Tooltip>
        </>
    );
}
