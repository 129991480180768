import React, {useState} from "react";
import {
    BooleanField,
    Datagrid,
    List,
    NumberField,
    TextField,
    DateTimeInput,
    WrapperField,
    FunctionField,
} from "react-admin";
import {TimestampDateField} from "../../Fields";
import {UserPopoverField} from "../../Popover";
import {EventUserList} from "./EventUsersList";
import {Modal} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ZipArchiveAction} from "./ZipArchive";
import {UserSelectInput} from "../../Inputs";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import {ActionsField} from "./ActionsField";

const eventFilters = [
    <DateTimeInput
        label={"From Date"}
        source={"StartDate"}
        parse={(date) => String(new Date(date).getTime() / 1000)}
    />,
    <DateTimeInput
        label={"To Date"}
        source={"EndDate"}
        parse={(date) => String(new Date(date).getTime() / 1000)}
    />,
    <UserSelectInput
        label={"Creator User"}
        source={"CreatorUserId"}
    />,
    <UserSelectInput
        label={"Participant User"}
        source={"ParticipantUserId"}
    />,
];

export const EventList = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentEventId, setCurrentEventId] = useState<number | null>(null);
    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, currentEventId: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentEventId(currentEventId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentEventId(null);
    };

    const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>, currentEventId: number) => {
        SetModalIsOpen(true);
        setCurrentEventId(currentEventId);
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
        setCurrentEventId(null);
    }

    return (
        <>
            <List
                title="Events"
                queryOptions={{
                    meta: {
                        overridePath: '/Event/Search',
                        alternativeId: 'eventId',
                        pagination: true,
                        dataExtractFunction: (data: { payload: any }) => {
                            return data.payload.results;
                        }
                    },
                    refetchOnMount: false,
                }}
                perPage={25}
                filters={eventFilters}
            >
                <ListFilterCleaner>
                    <Datagrid
                        bulkActionButtons={false}
                    >
                        <TextField source="eventId"/>
                        <TextField source="name"/>
                        <TimestampDateField source="startDate"/>
                        <TimestampDateField source="endDate"/>
                        {/* <TextField source="description"/> */}
                        {/* <TextField source="comments"/> */}
                        {/* <TextField source="location"/> */}
                        {/* <BooleanField source="isGuestsAutoApproval"/> */}
                        {/* <BooleanField source="isDelete"/> */}
                        <NumberField source="imageCount"/>
                        <TimestampDateField source="lastUploadTimestamp"/>
                        {/* <TextField source="eventType.name"/> */}
                        <WrapperField source={'creator'}>
                            <UserPopoverField
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                                anchorEl={anchorEl}
                                currentResourceId={currentEventId}
                                resourceIdKey={'eventId'}
                                resource={'creator'}
                            />
                        </WrapperField>
                        <FunctionField label={"Event Users"} render={(record: any) => (
                            <Button
                                onClick={(e) => handleOpenModal(e, record.eventId)}
                            >
                                Users
                            </Button>
                        )}/>
                        <FunctionField
                            label={"Zip Archive"}
                            render={(record: any) => (
                                <ZipArchiveAction record={record}/>
                            )}
                        />
                        <ActionsField/>
                    </Datagrid>
                </ListFilterCleaner>
            </List>
            <Modal
                open={modalIsOpen}
                onClose={handleCloseModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    width: '90vh',
                    height: '90vh',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    p: 4,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'auto'
                }}>
                    <Typography variant="h5" component="div">
                        Event Users
                    </Typography>
                    <EventUserList eventId={currentEventId!}/>
                </Box>
            </Modal>
        </>
    );
}
