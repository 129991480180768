import React, {useCallback, useState} from 'react';
import {useGetList} from "react-admin";

import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {FirmwarePickerType} from "../../types";

type FirmwareOptionType = FirmwarePickerType | null;

type FirmwarePickerProps = {
    value: FirmwareOptionType;
    onSelect: (value: FirmwarePickerType) => void;
}

export const FirmwarePicker = (props: FirmwarePickerProps) => {
    const {value: val, onSelect} = props;

    const [value, setValue] = useState<FirmwareOptionType>(val);

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload.results;
    }, []);

    const { data, isLoading, error } = useGetList(
        'firmware',
        {
            meta: {
                pagination: true,
                alternativeId: 'firmwareId',
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    if (error) {
        return <p>ERROR</p>;
    }

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: 300, height: 50, display: 'flex'}}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ width: 300, height: 50, display: 'flex' }}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as FirmwarePickerType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="firmware-picker"
                options={data?.map(op => ({label: op.notes, value: op.firmwareId})) ?? [] as FirmwareOptionType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props} key={option?.value}>{option?.label}</li>}
                sx={{ width: 300 }}
                renderInput={(params) => (
                    <TextField {...params} label="Search Firmware" />
                )}
            />
        </Box>
    );
}
