import React from "react";
import {Datagrid, List, TextField, TextInput} from "react-admin";
import {TimestampDateField} from "../../Fields";
import {ActionsField} from "./ActionsField";
import {isNumber} from "../../../utils";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";

const firmwareFilters = [
    <TextInput
        label="Firmware Id"
        source="FirmwareId"
        alwaysOn
        validate={
            [
                (value: string) => isNumber(value) ? undefined : "Must be a number",
            ]
        }
        resettable
    />,
];

export const FirmwareList = () => {
    return (
        <List
            title="Firmware"
            queryOptions={{
                meta: {
                    alternativeId: 'firmwareId',
                    pagination: true,
                    dataExtractFunction: (data: { payload: any }) => {
                        return data.payload.results;
                    }
                },
                refetchOnMount: true,
            }}
            perPage={25}
            filters={firmwareFilters}
        >
            <ListFilterCleaner>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="firmwareId"/>
                    <TextField source="notes"/>
                    <TimestampDateField source="uploadedAt"/>
                    <TextField source="blobUrl"/>
                    <ActionsField/>
                </Datagrid>
            </ListFilterCleaner>
        </List>
    );
};
