import React, {useState} from "react";

import {BooleanField, Datagrid, List, TextField, TextInput, WrapperField, FunctionField} from "react-admin";
import {SensitiveDataField, TimestampDateField} from "../../../Fields";
import {EnvironmentPopoverField, FirmwarePopoverField} from "../../../Popover";
import {ActionsField} from "./ActionsField";
import {UpsertEnvironmentAction} from "./UpsertEnvironmentAction";
import {UpsertFirmwareAction} from "./UpsertFirmwareAction";
import {UpsertAllowReRegAction} from "./UpsertAllowReRegAction";
import {ShowEquipmentProps} from "./ShowEquipmentProps";
import {isNumber} from "../../../../utils";
import {ListFilterCleaner} from "../../../ListFilterCleaner/ListFilterCleaner";
import {ActivateDeactivateActionsField} from "./ActivateDeactivateActionsField";

const equipmentFilters = [
    <TextInput
        label="Serial"
        source="Serial"
        resettable
    />,
    <TextInput
        label="Esn"
        source="Esn"
        resettable
    />,
    <TextInput
        label="FirmwareId"
        source="FirmwareId"
        validate={
            [
                (value: string) => isNumber(value) ? undefined : "Must be a number",
            ]
        }
        resettable
    />,
    <TextInput
        label="EnvironmentId"
        source="EnvironmentId"
        validate={
            [
                (value: string) => isNumber(value) ? undefined : "Must be a number",
            ]
        }
        resettable
    />,
];

export const EnvironmentManagerEquipmentList = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentEquipmentId, setCurrentEquipmentId] = useState<number | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, currentEquipmentId: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentEquipmentId(currentEquipmentId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentEquipmentId(null);
    };

    return (
        <List
            title="Equipments"
            queryOptions={{
                meta: {
                    alternativeId: 'equipmentId',
                    pagination: true,
                    dataExtractFunction: (data: { payload: any }) => {
                        return data.payload.results;
                    }
                },
                refetchOnMount: false,
            }}
            perPage={25}
            filters={equipmentFilters}
        >
            <ListFilterCleaner>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="equipmentId"/>
                    <WrapperField source={'serial'}>
                        <TextField source="serial"/>
                        <FunctionField label={"serial"} render={(record: any) => (
                            <ShowEquipmentProps record={record}/>
                        )}/>
                    </WrapperField>
                    <TextField source="esn"/>
                    <TimestampDateField source="regDate"/>
                    <SensitiveDataField source={"privateKey"}/>
                    <TextField source="regIp"/>
                    <TimestampDateField source="ipDate"/>
                    <BooleanField source="isActive"/>
                    <WrapperField source={'allowReReg'}>
                        <BooleanField source="allowReReg"/>
                        <FunctionField label={"Firmware"} render={(record: any) => (
                            <UpsertAllowReRegAction record={record}/>
                        )}/>
                    </WrapperField>
                    <WrapperField source={'firmware'}>
                        <FirmwarePopoverField
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                            currentResourceId={currentEquipmentId}
                            resourceIdKey={'equipmentId'}
                        />
                        <FunctionField label={"Firmware"} render={(record: any) => (
                            <UpsertFirmwareAction record={record}/>
                        )}/>
                    </WrapperField>
                    <WrapperField source={'environment'}>
                        <EnvironmentPopoverField
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                            currentResourceId={currentEquipmentId}
                            resourceIdKey={'equipmentId'}
                        />
                        <FunctionField label={"Environment"} render={(record: any) => (
                            <UpsertEnvironmentAction record={record}/>
                        )}/>
                    </WrapperField>
                    <ActionsField/>
                    <ActivateDeactivateActionsField/>
                </Datagrid>
            </ListFilterCleaner>
        </List>
    );
};
