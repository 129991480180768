import React, {useState, useRef, useEffect} from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import {FirmwarePicker} from "../../Inputs";
import {FirmwarePickerType} from "../../../types";

import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";

export type UpsertFirmwareActionProps = {
    record: any;
}
// TODO reuse existing component
export const UpsertFirmwareAction = ({record}: UpsertFirmwareActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedFirmware, setSelectedFirmware] = useState<FirmwarePickerType | null>(null);
    const currentFirmware = useRef<FirmwarePickerType | null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (record?.firmware) {
            currentFirmware.current = {label: record.firmware.notes, value: record.firmware.firmwareId};
        }
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: assignFirmware, isLoading: isLoadingAssignFirmware } = useMutation(() => {
        if (!selectedFirmware) {
            return;
        }
        return dataProvider.saPost(`Environment/${record.environmentId}/Firmware?FirmwareId=${selectedFirmware?.value}`, {});
    }, {
        onSuccess: data => {
            notify("Firmware assigned successfully", { type: 'success' });
        },
        onError: () => {
            notify("Firmware assignment failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('Environment');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelect = (firmware: FirmwarePickerType) => {
        setSelectedFirmware(firmware);
    }

    const assignFirmwareOp = async () => {
        if (!selectedFirmware) {
            return;
        }
        await assignFirmware();
    }

    const onAssign = async () => {
        if (record.firmware) {
            setDialogIsOpen(true);
            return;
        }
        await assignFirmwareOp();
        handleClose();
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await assignFirmwareOp();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingAssignFirmware) {
        return <CircularProgress />;
    }

    return (
        <>
            <Tooltip title={`${!record.firmware ? 'Set' : 'Edit'} Firmware`}>
                <IconButton onClick={handleOpen}>
                    {
                        !record.firmware ? <AddIcon/> : <EditIcon/>
                    }
                </IconButton>
            </Tooltip>
            <Popover
                id={record.equipmentId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 100,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <FirmwarePicker
                        value={record.firmware ? {
                            label: record.firmware.notes,
                            value: record.firmware.firmwareId
                        } : null}
                        onSelect={onSelect}
                    />
                    <Button
                        disabled={!selectedFirmware}
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onAssign}>
                        Save
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Override Firmware?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to override the firmware?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

