import React from "react";

import {FunctionField} from "react-admin";
import {DeleteEvent} from "./DeleteEvent";
import {RestoreEvent} from "./RestoreEvent";
import {EditEvent} from "./EditEventAction";
import Box from "@mui/material/Box";
import {HeartbeatPopoverField} from "./HeartbeatPopoverField";
import {AddImagesPopoverField} from "./AddImagesPopoverField";


export const ActionsField = () => {
    return (
        <FunctionField
            label={"Actions"}
            render={
                (record: any) => {
                    return (
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <AddImagesPopoverField record={record} disabled={record.isDelete}/>
                            <HeartbeatPopoverField record={record}/>
                            {
                                record.isDelete ? <RestoreEvent record={record}/> : <>
                                    <EditEvent record={record}/>
                                    <DeleteEvent record={record}/>
                                </>
                            }
                        </Box>
                    );
                }
            }
        />
    );
};
