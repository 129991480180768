import React from "react";
import {
    useGetList,
    useNotify,
    useRedirect,
    EditContextProvider,
} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";
import {UpsertUserForm} from "./UpsertUserForm";
import {UserUpdateInput} from "../../../types";
import {useParams} from "react-router-dom";
import {Card} from "@mui/material";

export const UserUpdate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {id: userId} = useParams();

    const {data, isLoading, error} = useGetList(
        'user',
        {
            filter: {'Limit': 1, 'Offset': 0, 'UserId': userId, 'IncludeDeleted': true},
            meta: {
                alternativeId: 'userId',
                pagination: true,
                dataExtractFunction: (data: { payload: any }) => {
                    return data.payload.results;
                }
            },
        },
        {
            refetchOnMount: false,
        }
    );

    const {
        mutateAsync: updateUser,
        isLoading: isLoadingUpdateUser
    } = useMutation((input: UserUpdateInput) => {
        return dataProvider.saPost(`User/${userId}`, input);
    }, {
        onSuccess: async (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`User update failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("User updated successfully", {type: 'success'});
                redirect('list', 'User');
            }
        },
        onError: (data) => {
            notify("User update failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`User`);
        }
    });

    if (isLoading) {
        return <SimpleBackdrop open={isLoading}/>;
    }

    if (error || !data || !data.length) {
        redirect('list', 'User');
        return <></>;
    }

    const obj = data[0];
    const record = {
        firstName: obj.firstName,
        lastName: obj.lastName,
        email: obj.email,
        phoneNumber: obj.phoneNumber,
    };

    if (!record) {
        redirect('list', 'User');
        return <></>;
    }

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Edit User - {userId}
            </Typography>
            <SimpleBackdrop open={isLoadingUpdateUser}/>
            <Card>
                <EditContextProvider value={{
                    data: record,
                    record: record,
                    defaultTitle: 'Edit User',
                    resource: 'User',
                    isLoading: isLoading,
                    isFetching: isLoading,
                    redirect: 'list',
                    // @ts-ignore
                    refetch: () => {
                    },
                }}>
                    <UpsertUserForm onSubmit={(e) => updateUser(e as UserUpdateInput)} isUpdate={true}/>
                </EditContextProvider>
            </Card>
        </>
    );
};
