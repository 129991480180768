import {required, SimpleForm, TextInput} from "react-admin";
import React from "react";
import {EnvironmentUpdateInput, EnvironmentCreateInput} from "../../../types";


interface UpsertEnvironmentFormProps {
    onSubmit: (input: EnvironmentUpdateInput | EnvironmentCreateInput) => void;
    isUpdate: boolean;
}

export const UpsertEnvironmentForm = (props: UpsertEnvironmentFormProps) => {
    const {onSubmit, isUpdate} = props;
    return (
        <SimpleForm
            onSubmit={async (formValues) => {
                await onSubmit(formValues as EnvironmentUpdateInput);
                return
            }}
            sx={{width: '50%'}}
        >
            {
                !isUpdate && (
                    <TextInput source="name" validate={[required()]} fullWidth/>
                )
            }
            <TextInput source="azFnUrl" validate={[required()]} fullWidth/>
            <TextInput source="azFnKey" validate={[required()]} fullWidth/>
        </SimpleForm>
    )
}
