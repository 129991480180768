import React from "react";
import {Datagrid, FunctionField, List, TextField, TextInput, WrapperField} from "react-admin";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import {isNumber} from "../../../utils";
import {ActionsField} from "./ActionsField";
import {EditEventAction} from "./EditEventTypeAction";

const eventTypeFilters = [
    <TextInput
        label="Event Type Id"
        source="EventTypeId"
        validate={
            [
                (value: string) => isNumber(value) ? undefined : "Must be a number",
            ]
        }
        resettable
    />,

    <TextInput
        label="Event Type Name"
        source="Name"
        resettable
    />,
];

export const EventTypeList = () => (
    <List
        title="Event Types"
        queryOptions={{
            meta: {
                alternativeId: 'eventTypeId',
                pagination: false,
                dataExtractFunction: (data: { payload: any }) => {
                    return data.payload;
                }
            },
            refetchOnMount: true,
        }}
        perPage={25}
        filters={eventTypeFilters}
    >
        <ListFilterCleaner>
            <Datagrid bulkActionButtons={false}>
                <TextField source="eventTypeId"/>
                <WrapperField source={'name'}>
                    <TextField source="name"/>
                    <FunctionField label={"Edit Event Type"} render={(record: any) => (
                        <EditEventAction record={record}/>
                    )}/>
                </WrapperField>
                <ActionsField/>
            </Datagrid>
        </ListFilterCleaner>
    </List>
);
