import React from "react";
import {Create, SimpleForm, TextInput, FileInput, FileField, useNotify, useRedirect} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";

export const FirmwareCreate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {
        mutateAsync: addFirmware,
        isLoading: isLoadingAddFirmware
    } = useMutation(({notes, file}: { notes: string, file: File }) => {
        if (notes) {
            return dataProvider.saPostFile(`Firmware?Notes=${notes}`, {}, file);
        }
        return dataProvider.saPostFile(`Firmware`, {}, file);
    }, {
        onSuccess: data => {
            notify("New Firmware uploaded successfully", {type: 'success'});
            redirect('list', 'Firmware');
        },
        onError: (data) => {
            notify("New Firmware upload failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Firmware');
        }
    });

    return (
        <>
            <SimpleBackdrop open={isLoadingAddFirmware}/>
            <Create>
                <SimpleForm onSubmit={async (formValues) => {
                    if (!formValues.file) {
                        notify("No file selected", {type: 'error'});
                        return;
                    }
                    await addFirmware({
                        notes: formValues.notes,
                        file: formValues.file.rawFile
                    });
                }}>
                    <TextInput source="notes" fullWidth/>
                    <FileInput source="file" label="Firmware" multiple={false} isRequired={true}>
                        <FileField source="src" title="title"/>
                    </FileInput>
                </SimpleForm>
            </Create>
        </>
    );
};
