import React from "react";

import {FunctionField} from "react-admin";
import {DownloadFirmware} from "./DownloadFirmware";
import {DeleteFirmware} from "./DeleteFirmware";

export const ActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: any) => {
                    return (
                        <>
                            <DownloadFirmware record={record}/>
                            <DeleteFirmware record={record}/>
                        </>
                    );
                }
            }
        />
    );
};
