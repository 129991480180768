import React from "react";

import {FunctionField} from "react-admin";
import {DeleteEnvironment} from "./DeleteEnvironment";
import {SetAsDefaultEnvironment} from "./SetAsDefualtEnvironment";
import {EditEnvironment} from "./EditEnvironmentAction";

export const ActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: any) => {
                    return (
                        <>
                            <EditEnvironment record={record}/>
                            <SetAsDefaultEnvironment record={record}/>
                            <DeleteEnvironment record={record}/>
                        </>
                    );
                }
            }
        />
    );
};
