import React, {useState} from "react";

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {IconButton, Tooltip} from "@mui/material";
import {config, useToken} from "../../../utils";
import CircularProgress from "@mui/material/CircularProgress";

export type DownloadFirmwareActionProps = {
    record: any;
}

export const DownloadFirmware = ({record}: DownloadFirmwareActionProps) => {

    const {accessToken} = useToken();
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = () => {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        fetch(`${config.API_URL}/Firmware/${record.firmwareId}`, requestOptions)
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${record.firmwareId}.zip`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                return Promise.reject({Error: 'Something Went Wrong', err});
            }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Tooltip title="Download Firmware">
            {
                loading ? <CircularProgress/> : (
                    <IconButton onClick={handleClick}>
                        <CloudDownloadIcon/>
                    </IconButton>
                )
            }
        </Tooltip>
    );
}
