import React from "react";

import {FunctionField} from "react-admin";
import Box from "@mui/material/Box";
import {UnBlockUser} from "./UnBlockUser";
import {BlockUser} from "./BlockUser";
import {DeleteUser} from "./DeleteUser";
import {EditUser} from "./EditUserAction";
import {SendOTPToUser} from "./SendOTPToUser";
import {ChangeUserPhone} from "./ChangeUserPhone";


export const ActionsField = () => {
    return (
        <FunctionField
            label={"Actions"}
            render={
                (record: any) => {
                    return (
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            {
                                record.isDeleted ? null : (
                                    <>
                                        <ChangeUserPhone record={record}/>
                                        <SendOTPToUser record={record}/>
                                        <EditUser record={record}/>
                                        {
                                            record.isBlocked ? <UnBlockUser record={record}/> :
                                                <BlockUser record={record}/>
                                        }
                                        <DeleteUser record={record}/>
                                    </>
                                )
                            }
                        </Box>
                    );
                }
            }
        />
    );
};
