import React, {useState, useEffect} from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export type EditEventActionProps = {
    record: any;
}

export const EditEventAction = ({record}: EditEventActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentEventTypeName, setCurrentEventTypeName] = useState<string>(record.name);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setCurrentEventTypeName(record.name);
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: updateEventType, isLoading: isLoadingUpdateEventType } = useMutation(() => {
        return dataProvider.saPost(`EventType/${record.eventTypeId}?Name=${currentEventTypeName}`, {});
    }, {
        onSuccess: data => {
            notify("Event Type updated successfully", { type: 'success' });
        },
        onError: () => {
            notify("Event Type update failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('EventType');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSave = async () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await updateEventType();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingUpdateEventType) {
        return <CircularProgress />;
    }

    return (
        <>
            <Tooltip title={`Edit Event Type`}>
                <IconButton onClick={handleOpen}>
                    <EditIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={record.eventTypeId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 150,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{p: 2}}>Edit Event Type</Typography>
                    <TextField
                        value={currentEventTypeName}
                        label="Event Type"
                        variant="outlined"
                        onChange={(e) => setCurrentEventTypeName(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onSave}>
                        Save
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Edit Event Type?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to edit this event type?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

