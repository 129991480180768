import React, {useCallback, useState} from 'react';
import {useGetList} from "react-admin";

import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {EnvironmentPickerType} from "../../types";

type EnvironmentOptionType = EnvironmentPickerType | null;

type EnvironmentPickerProps = {
    value: EnvironmentOptionType;
    onSelect: (value: EnvironmentPickerType) => void;
}

export const EnvironmentPicker = (props: EnvironmentPickerProps) => {
    const {value: val, onSelect} = props;

    const [value, setValue] = useState<EnvironmentOptionType>(val);

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload.results;
    }, []);

    const { data, isLoading, error } = useGetList(
        'environment',
        {
            meta: {
                pagination: true,
                alternativeId: 'environmentId',
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: false,
        }
    );

    if (error) {
        return <p>ERROR</p>;
    }

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: 300, height: 50, display: 'flex'}}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ width: 300, height: 50, display: 'flex' }}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as EnvironmentPickerType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="environment-picker"
                options={data?.map(op => ({label: op.name, value: op.environmentId})) ?? [] as EnvironmentOptionType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props}>{option?.label}</li>}
                sx={{ width: 300 }}
                renderInput={(params) => (
                    <TextField {...params} label="Search Environment" />
                )}
            />
        </Box>
    );
}
