import React, {useState} from "react";
import Box from "@mui/material/Box";
import {
    IconButton,
    MenuItem, Modal,
    Select,
    Tooltip
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import {FileField, FileInput, required, SaveButton, SimpleForm, useNotify, useRedirect} from "react-admin";
import Typography from "@mui/material/Typography";
import {EquipmentPicker, EventPhotographersPicker} from "../../Inputs";
import {EquipmentPickerType, PhotographerPickerType} from "../../../types";
import Button from "@mui/material/Button";
import SimpleBackdrop from "../../SimpleBackdrop";

export type AddImagesPopoverFieldProps = {
    record: any;
    disabled?: boolean;
}

export const AddImagesPopoverField = ({record, disabled}: AddImagesPopoverFieldProps) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [selectedPhotographer, setSelectedPhotographer] = useState<PhotographerPickerType | null>(null);
    const [selectedEquipment, setSelectedEquipment] = useState<EquipmentPickerType | null>(null);
    const [selectedOffset, setSelectedOffset] = useState<string>('+00:00');

    const [step, setStep] = useState<number>(0);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const {
        mutateAsync: addImages,
        isLoading: isLoadingAddImages
    } = useMutation(({files}: { files: File[] }) => {
        if (files.length === 0 || (!selectedPhotographer || !selectedEquipment)) {
            notify("Missing Required fields", {type: 'error'});
            return Promise.reject();
        }
        return dataProvider.saPostFiles(`Image/Upload?EventId=${record.eventId}&UserId=${selectedPhotographer.value}&EquipmentId=${selectedEquipment.value}&Offset=${selectedOffset}`, {}, files);
    }, {
        onSuccess: async (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`Images upload failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("Images uploaded successfully", {type: 'success'});
                handleClose();
                redirect('list', 'Event');
            }
        },
        onError: (data) => {
            notify("Images upload failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Event');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedEquipment(null);
        setSelectedOffset('+00:00');
        setSelectedPhotographer(null);
        setStep(0);
    };

    return (
        <>
            <SimpleBackdrop open={isLoadingAddImages}/>
            <Tooltip title={`Add Images`}>
                <IconButton onClick={handleOpen} disabled={disabled}>
                    <AddPhotoAlternateIcon/>
                </IconButton>
            </Tooltip>
            <Modal
                open={!!anchorEl}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
            >
                <Box
                    sx={{
                        minHeight: 320,
                        minWidth: 550,
                        maxHeight: '70%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-between',
                        overflow: 'auto',
                        padding: 1,
                        backgroundColor: '#FFFFFF',
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: '2px solid #000',
                        boxShadow: 24,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Images - Event {record.eventId}
                    </Typography>
                    {
                        step === 0 && (
                            <>
                                <EventPhotographersPicker
                                    eventId={record.eventId}
                                    onSelect={setSelectedPhotographer}
                                    value={selectedPhotographer}
                                />
                                <EquipmentPicker
                                    userId={selectedPhotographer?.value}
                                    disabled={!selectedPhotographer}
                                    onSelect={setSelectedEquipment}
                                    value={selectedEquipment}
                                />
                                <Box sx={{width: 300, height: 50, display: 'flex'}}>
                                    <Select
                                        labelId="offset-label"
                                        id="offset-select"
                                        value={selectedOffset}
                                        label="Offset"
                                        onChange={e => setSelectedOffset(e.target.value as string)}
                                        sx={{width: 300}}
                                        variant={'filled'}
                                    >
                                        <MenuItem value={'-12:00'}>-12:00</MenuItem>
                                        <MenuItem value={'-11:00'}>-11:00</MenuItem>
                                        <MenuItem value={'-10:00'}>-10:00</MenuItem>
                                        <MenuItem value={'-09:30'}>-09:30</MenuItem>
                                        <MenuItem value={'-09:00'}>-09:00</MenuItem>
                                        <MenuItem value={'-08:00'}>-08:00</MenuItem>
                                        <MenuItem value={'-07:00'}>-07:00</MenuItem>
                                        <MenuItem value={'-06:00'}>-06:00</MenuItem>
                                        <MenuItem value={'-05:00'}>-05:00</MenuItem>
                                        <MenuItem value={'-04:00'}>-04:00</MenuItem>
                                        <MenuItem value={'-03:30'}>-03:30</MenuItem>
                                        <MenuItem value={'-03:00'}>-03:00</MenuItem>
                                        <MenuItem value={'-02:00'}>-02:00</MenuItem>
                                        <MenuItem value={'-01:00'}>-01:00</MenuItem>
                                        <MenuItem value={'-00:00'}>-00:00</MenuItem>
                                        <MenuItem value={'+00:00'}>+00:00</MenuItem>
                                        <MenuItem value={'+01:00'}>+01:00</MenuItem>
                                        <MenuItem value={'+02:00'}>+02:00</MenuItem>
                                        <MenuItem value={'+03:00'}>+03:00</MenuItem>
                                        <MenuItem value={'+03:30'}>+03:30</MenuItem>
                                        <MenuItem value={'+04:00'}>+04:00</MenuItem>
                                        <MenuItem value={'+04:30'}>+04:30</MenuItem>
                                        <MenuItem value={'+05:00'}>+05:00</MenuItem>
                                        <MenuItem value={'+05:30'}>+05:30</MenuItem>
                                        <MenuItem value={'+05:45'}>+05:45</MenuItem>
                                        <MenuItem value={'+06:00'}>+06:00</MenuItem>
                                        <MenuItem value={'+06:30'}>+06:30</MenuItem>
                                        <MenuItem value={'+07:00'}>+07:00</MenuItem>
                                        <MenuItem value={'+08:00'}>+08:00</MenuItem>
                                        <MenuItem value={'+08:45'}>+08:45</MenuItem>
                                        <MenuItem value={'+09:00'}>+09:00</MenuItem>
                                        <MenuItem value={'+09:30'}>+09:30</MenuItem>
                                        <MenuItem value={'+10:00'}>+10:00</MenuItem>
                                        <MenuItem value={'+10:30'}>+10:30</MenuItem>
                                        <MenuItem value={'+11:00'}>+11:00</MenuItem>
                                        <MenuItem value={'+12:00'}>+12:00</MenuItem>
                                        <MenuItem value={'+12:45'}>+12:45</MenuItem>
                                        <MenuItem value={'+13:00'}>+13:00</MenuItem>
                                        <MenuItem value={'+14:00'}>+14:00</MenuItem>
                                    </Select>
                                </Box>
                                <Button
                                    sx={{marginTop: 2}}
                                    variant={'contained'}
                                    onClick={() => setStep(1)}
                                    disabled={!selectedPhotographer || !selectedOffset || !selectedEquipment}
                                >
                                    Next
                                </Button>
                            </>
                        )
                    }
                    {
                        step === 1 && (
                            <>
                                <Typography variant="h6" component="div" sx={{marginBottom: 1, fontSize: 14}}>
                                    Photographer: {selectedPhotographer?.label}
                                </Typography>
                                <Typography variant="h6" component="div" sx={{marginBottom: 1, fontSize: 14}}>
                                    Equipment: {selectedEquipment?.label}
                                </Typography>
                                <Typography variant="h6" component="div" sx={{marginBottom: 1, fontSize: 14}}>
                                    Offset: {selectedOffset}
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
                                    <SimpleForm onSubmit={async (formValues) => {
                                        if (!formValues.files) {
                                            notify("No files selected", {type: 'error'});
                                            return;
                                        }
                                        await addImages({
                                            files: formValues.files.map((file: any) => file.rawFile),
                                        });
                                    }} toolbar={false}>
                                        <FileInput source="files" label="Photos" multiple={true} validate={[required()]}
                                                   accept={["image/jpeg", "image/jpg"]}>
                                            <FileField source="src" title="title"/>
                                        </FileInput>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                            alignItems: 'flex-start',
                                            width: '50%'
                                        }}>
                                            <Button
                                                variant={'contained'}
                                                onClick={() => setStep(0)}
                                            >
                                                Back
                                            </Button>
                                            <SaveButton/>
                                        </Box>
                                    </SimpleForm>
                                </Box>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
}

