import React, {useState} from 'react';

import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {ZipArchiveKindPickerType} from "../../types";

export type ZipArchiveKindType = ZipArchiveKindPickerType | null;

type ZipArchiveKindPickerProps = {
    value: ZipArchiveKindType;
    onSelect: (value: ZipArchiveKindType) => void;
}

const data = [
    {
        label: 'Originals',
        value: 1,
    },
    {
        label: 'Edited',
        value: 2,
    },
    {
        label: 'Both',
        value: 3,
    }
];

export const ZipArchiveKindPicker = (props: ZipArchiveKindPickerProps) => {
    const {value: val, onSelect} = props;

    const [value, setValue] = useState<ZipArchiveKindType>(val);

    return (
        <Box sx={{width: 300, height: 50, display: 'flex'}}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as ZipArchiveKindType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="zip-archive-kind-type-picker"
                options={data ?? [] as ZipArchiveKindType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props} key={option?.value}>{option?.label}</li>}
                sx={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label="Search Archive Kind"/>
                )}
            />
        </Box>
    );
}
