import {required, SimpleForm, TextInput} from "react-admin";
import React from "react";
import {UserUpdateInput, UserCreateInput} from "../../../types";

interface UpsertUserFormProps {
    onSubmit: (input: UserUpdateInput | UserCreateInput) => void;
    isUpdate: boolean;
}

export const UpsertUserForm = (props: UpsertUserFormProps) => {
    const {onSubmit, isUpdate} = props;
    return (
        <SimpleForm
            onSubmit={async (formValues) => {
                await onSubmit(formValues as UserCreateInput);
                return
            }}
            sx={{width: '50%'}}
        >
            <TextInput source="firstName" validate={[required()]} fullWidth/>
            <TextInput source="lastName" validate={[required()]} fullWidth/>
            <TextInput source="email" validate={[required()]} fullWidth/>
            {
                !isUpdate && (
                    <TextInput source="phoneNumber" validate={[required()]} fullWidth/>
                )
            }
        </SimpleForm>
    )
}
