import React from "react";

import {useRecordContext} from "ra-core";
import get from 'lodash/get';
import {IconButton, Tooltip, Container} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from "@mui/material/Typography";
import {useNotify} from "react-admin";
import Popover from "@mui/material/Popover";

export const SensitiveDataField = (props: any) => {
    const notify = useNotify();
    const record = useRecordContext(props);
    const value = get(record, props.source);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const copyKey = () => {
        navigator.clipboard.writeText(value);
        notify(`Key copied!`, { type: 'success' });
    }

    return (
        <Container
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
            }}
        >
            <Tooltip title="Show key">
                <IconButton onClick={handleClick}>
                    <RemoveRedEyeIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Copy key">
                <IconButton onClick={copyKey}>
                    <ContentCopyIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{p: 2}}>
                    {value}
                </Typography>
            </Popover>
        </Container>
    );
}
