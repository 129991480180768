import React from "react";
import {
    Create,
    useNotify,
    useRedirect,
} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";
import {EnvironmentCreateInput} from "../../../types";
import {UpsertEnvironmentForm} from "./UpsertEnvironmentForm";

export const EnvironmentCreate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {
        mutateAsync: addEnvironment,
        isLoading: isLoadingAddEnvironment
    } = useMutation((input: EnvironmentCreateInput) => {
        return dataProvider.saPost(`Environment`, input);
    }, {
        onSuccess: async (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`Environment creation failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("Environment created successfully", {type: 'success'});
                redirect('list', 'Environment');
            }
        },
        onError: (data) => {
            notify("User creation failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`Environment`);
        }
    });

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Add New Environment
            </Typography>
            <SimpleBackdrop open={isLoadingAddEnvironment}/>
            <Create>
                <UpsertEnvironmentForm onSubmit={(e) => addEnvironment(e as EnvironmentCreateInput)} isUpdate={false}/>
            </Create>
        </>
    );
};
