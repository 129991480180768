import React from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {FunctionField} from "react-admin";

type FirmwarePopoverFieldProps = {
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>, currentEnvironmentId: number) => void;
    handleClose: () => void;
    anchorEl: HTMLButtonElement | null;
    currentResourceId: number | null;
    resourceIdKey: string;
}

export const FirmwarePopoverField = (props: FirmwarePopoverFieldProps) => {
    const {
        handleOpen,
        handleClose,
        anchorEl,
        currentResourceId,
        resourceIdKey
    } = props;

    return (
        <FunctionField label={"Firmware"} render={(record: any) => {
            if (!record.firmware) {
                return null;
            }
            return (
                <>
                    <Button
                        onClick={(e) => handleOpen(e, record[resourceIdKey])}
                    >
                        Firmware-{record.firmware.firmwareId}
                    </Button>
                    <Popover
                        id={record[resourceIdKey]}
                        open={currentResourceId === record[resourceIdKey]}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{p: 1}}><b>firmware id:</b> {record.firmware?.firmwareId}</Typography>
                        <Typography sx={{p: 1}}><b>notes:</b> {record.firmware?.notes}</Typography>
                        <Typography sx={{p: 1}}><b>uploaded
                            at:</b> {new Date(record.firmware?.uploadedAt * 1000).toLocaleString()}
                        </Typography>
                    </Popover>
                </>
            );
        }}/>
    );
}
