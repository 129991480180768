import React, {useState} from "react";

import Popover from "@mui/material/Popover";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";

import FolderZipIcon from '@mui/icons-material/FolderZip';

import {useNotify} from "react-admin";
import {
    UserOptionType,
    UserPicker,
    ZipArchiveKindType,
    ZipArchiveKindPicker,
    ZipGroupTypePicker,
    ZipGroupTypeType
} from "../../Inputs";
import {useMutation} from "react-query";
import {useDataProvider} from "ra-core";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";

export type ZipArchiveActionProps = {
    record: any;
}

export const ZipArchiveAction = ({record}: ZipArchiveActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedZipArchiveKind, setSelectedZipArchiveKind] = useState<ZipArchiveKindType | null>(null);
    const [selectedZipGroupType, setSelectedZipGroupType] = useState<ZipGroupTypeType | null>(null);
    const [selectedUser, setSelectedUser] = useState<UserOptionType | null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const dataProvider = useDataProvider();

    const {mutateAsync: createZipArchive, isLoading: isLoadingCreateZipArchive} = useMutation(() => {
        if (!selectedZipArchiveKind || !selectedUser) {
            return;
        }
        let address = `Event/${record.eventId}/ZipArchive?UserId=${selectedUser.value}&Kind=${selectedZipArchiveKind.value}`;

        if (selectedZipGroupType) {
            address += `&GroupTypeId=${selectedZipGroupType.value}`;
        }

        return dataProvider.saPost(address, {});
    }, {
        onSuccess: (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`Creation of zip archive failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("Zip archive created successfully", {type: 'success'});
            }
        },
        onError: () => {
            notify("Creation of zip archive failed", {type: 'error'});
        },
        onSettled: () => {
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectZipArchiveKindType = (archiveKind: ZipArchiveKindType) => {
        setSelectedZipArchiveKind(archiveKind);
    }

    const onSelectZipGroupType = (groupType: ZipGroupTypeType) => {
        setSelectedZipGroupType(groupType);
    }

    const onSelectUser = (user: UserOptionType) => {
        setSelectedUser(user);
    }

    const onCreateZipArchive = () => {
        setDialogIsOpen(true);
    }

    const createZipArchiveOp = async () => {
        if (!selectedZipArchiveKind || !selectedUser) {
            notify("Please select a user and archive type", {type: 'error'});
            return;
        }
        await createZipArchive();
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await createZipArchiveOp();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingCreateZipArchive) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`Create Zip Archive`}>
                <IconButton onClick={handleOpen}>
                    <FolderZipIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={String(record.eventId)}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 300,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="h6">Create Zip Archive</Typography>
                    <UserPicker value={null} onSelect={onSelectUser}/>
                    <ZipArchiveKindPicker
                        value={null}
                        onSelect={onSelectZipArchiveKindType}
                    />
                    <ZipGroupTypePicker
                        value={null}
                        onSelect={onSelectZipGroupType}
                    />
                    <Button
                        disabled={!selectedZipArchiveKind || !selectedUser}
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onCreateZipArchive}>
                        Create
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Create Zip Archive?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to create a zip archive?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

