import React from "react";

import LogoDevIcon from '@mui/icons-material/LogoDev';
import {IconButton, Tooltip} from "@mui/material";

export type ConnectToVpnActionProps = {
    record: any;
}

const gerElkLink = (serial: string) => `https://evntoelk.kb.eastus.azure.elastic-cloud.com:9243/app/logs/stream?flyoutOptions=(flyoutId:!n,flyoutVisibility:hidden,surroundingLogsId:!n)&logPosition=(end:now,start:now-5h,streamLive:!f)&logFilter=(language:kuery,query:%27agent.hostname:%20%22${serial}%22%27)`

export const ShowLogs = ({record}: ConnectToVpnActionProps) => {

    const handleClick = () => {
        const link = gerElkLink(record.serial);
        const win = window.open(link, '_blank');
        win?.focus();
    }

    return (
        <Tooltip title="Show Logs">
            <IconButton onClick={handleClick}>
                <LogoDevIcon/>
            </IconButton>
        </Tooltip>
    );
}
