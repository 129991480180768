import React, {useCallback, useEffect, useState} from 'react';

import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {ZipGroupTypePickerType} from "../../types";
import {useGetList} from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";

export type ZipGroupTypeType = ZipGroupTypePickerType | null;

type ZipGroupTypePickerProps = {
    value: ZipGroupTypeType;
    onSelect: (value: ZipGroupTypeType) => void;
}

export const ZipGroupTypePicker = (props: ZipGroupTypePickerProps) => {
    const {value: val, onSelect} = props;

    const [value, setValue] = useState<ZipGroupTypeType>(val);

    const [data, setData] = useState<any[] | undefined>([]);

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload;
    }, []);

    const {data: originalData, isLoading, error} = useGetList(
        'Event/ZipGroupTypes',
        {
            meta: {
                pagination: false,
                alternativeId: 'groupTypeId',
                dataExtractFunction: dataExtractFunction,
            },
        },
        {
            refetchOnMount: true,
            queryKey: ['event', 'ZipGroupTypes'],
        }
    );

    useEffect(() => {
        if (!data?.length) {
            setData(originalData?.map((item: any) => ({value: item.groupTypeId, label: item.name})));
        }
    }, [originalData, data?.length]);

    if (error) {
        return null;
    }

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{width: 300, height: 50, display: 'flex'}}
            >
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Box sx={{width: 300, height: 50, display: 'flex'}}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    if (newValue) {
                        onSelect(newValue as ZipGroupTypeType);
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => String(option?.value) === String(value?.value)}
                id="zip-archive-group-type-picker"
                options={data ?? [] as ZipGroupTypeType[]}
                getOptionLabel={(option) => {
                    return option?.label ?? '';
                }}
                renderOption={(props, option) => <li {...props} key={option?.value}>{option?.label}</li>}
                sx={{width: 300}}
                renderInput={(params) => (
                    <TextField {...params} label="Search Group Type"/>
                )}
            />
        </Box>
    );
}
