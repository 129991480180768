import React from "react";
import {BooleanField, Datagrid, List, TextField, TextInput} from "react-admin";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import {ActionsField} from "./ActionsField";
import {isNumber} from "../../../utils";

const userFilters = [
    <TextInput
        label="Phone Number"
        source="PhoneNumber"
        alwaysOn
        resettable
    />,
    <TextInput
        label="UserId"
        source="UserId"
        validate={
            [
                (value: string) => isNumber(value) ? undefined : "Must be a number",
            ]
        }
        resettable
    />
];

export const UserList = () => (
    <List
        title="Users"
        queryOptions={{
            meta: {
                alternativeId: 'userId',
                pagination: true,
                dataExtractFunction: (data: { payload: any }) => {
                    return data.payload.results;
                }
            },
            refetchOnMount: false,
        }}
        filter={{'IncludeDeleted': true}}
        perPage={25}
        filters={userFilters}
    >
        <ListFilterCleaner>
            <Datagrid bulkActionButtons={false}>
                <TextField source="userId"/>
                <TextField source="firstName"/>
                <TextField source="lastName"/>
                <TextField source="phoneNumber"/>
                <TextField source="email"/>
                <BooleanField source="isDeleted"/>
                <BooleanField source="isBlocked"/>
                <ActionsField/>
            </Datagrid>
        </ListFilterCleaner>
    </List>
);
