import React from "react";
import {
    useGetList,
    useNotify,
    useRedirect,
    EditContextProvider,
} from "react-admin";
import {useMutation, useQueryClient} from "react-query";
import {useDataProvider} from "ra-core";
import SimpleBackdrop from "../../SimpleBackdrop";
import Typography from "@mui/material/Typography";
import {UpsertEventForm} from "./UpsertEventForm";
import {EventUpdateInput} from "../../../types";
import {useParams} from "react-router-dom";
import {Card} from "@mui/material";

export const EventUpdate = () => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const {id: eventId} = useParams();

    const {data, isLoading, error} = useGetList(
        'event',
        {
            filter: {'Limit': 1, 'Offset': 0, 'EventId': eventId},
            meta: {
                overridePath: '/Event/Search',
                alternativeId: 'eventId',
                pagination: true,
                dataExtractFunction: (data: { payload: any }) => {
                    return data.payload.results;
                }
            },
        },
        {
            refetchOnMount: false,
        }
    );

    const {
        mutateAsync: updateEvent,
        isLoading: isLoadingUpdateEvent
    } = useMutation((input: EventUpdateInput) => {
        return dataProvider.saPost(`Event/${eventId}`, input);
    }, {
        onSuccess: async (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`Event update failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("Event updated successfully", {type: 'success'});
                redirect('list', 'Event');
            }
        },
        onError: (data) => {
            notify("Event update failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`Event`);
        }
    });

    if (isLoading) {
        return <SimpleBackdrop open={isLoading}/>;
    }

    if (error || !data || !data.length) {
        redirect('list', 'Event');
        return <></>;
    }

    const obj = data[0];
    const record = {
        name: obj.name,
        eventTypeId: obj.eventType.eventTypeId,
        start: obj.startDate,
        end: obj.endDate,
        description: obj.description,
        comments: obj.comments,
        location: obj.location,
        isGuestsAutoApproval: obj.isGuestsAutoApproval,
    };

    if (!record) {
        redirect('list', 'Event');
        return <></>;
    }

    return (
        <>
            <Typography variant="h4" gutterBottom sx={{marginTop: 5}}>
                Edit Event - {eventId}
            </Typography>
            <SimpleBackdrop open={isLoadingUpdateEvent}/>
            <Card>
                <EditContextProvider value={{
                    data: record,
                    record: record,
                    defaultTitle: 'Edit Event',
                    resource: 'Event',
                    isLoading: isLoading,
                    isFetching: isLoading,
                    redirect: 'list',
                    // @ts-ignore
                    refetch: () => {
                    },
                }}>
                    <UpsertEventForm onSubmit={(e) => updateEvent(e as EventUpdateInput)} isUpdate={true}/>
                </EditContextProvider>
            </Card>
        </>
    );
};
