import React, {useState, useRef, useEffect} from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import {EnvironmentPickerType} from "../../../../types";
import {EnvironmentPicker} from "../../../Inputs";

import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";

export type UpsertEnvironmentActionProps = {
    record: any;
}

export const UpsertEnvironmentAction = ({record}: UpsertEnvironmentActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentPickerType | null>(null);
    const currentEnvironment = useRef<EnvironmentPickerType | null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (record?.environment) {
            currentEnvironment.current = {label: record.environment.name, value: record.environment.environmentId};
        }
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: assignEnvironment, isLoading: isLoadingAssignEnvironment } = useMutation(() => {
        if (!selectedEnvironment) {
            return;
        }
        return dataProvider.saPost(`Equipment/${record.equipmentId}/Environment?EnvironmentId=${selectedEnvironment?.value}`, {});
    }, {
        onSuccess: data => {
            notify("Environment assigned successfully", { type: 'success' });
        },
        onError: () => {
            notify("Environment Assignment failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('Equipment');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelect = (environment: EnvironmentPickerType) => {
        setSelectedEnvironment(environment);
    }

    const assignEnvironmentOp = async () => {
        if (!selectedEnvironment) {
            return;
        }
        await assignEnvironment();
    }

    const onAssign = async () => {
        if (record.environment) {
            setDialogIsOpen(true);
            return;
        }
        await assignEnvironmentOp();
        handleClose();
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await assignEnvironmentOp();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingAssignEnvironment) {
        return <CircularProgress />;
    }

    return (
        <>
            <Tooltip title={`${!record.environment ? 'Set' : 'Edit'} Environment`}>
                <IconButton onClick={handleOpen}>
                    {
                        !record.environment ? <AddIcon/> : <EditIcon/>
                    }
                </IconButton>
            </Tooltip>
            <Popover
                id={record.environmentId}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 100,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <EnvironmentPicker
                        value={record.environment ? {
                            label: record.environment.name,
                            value: record.environment.environmentId
                        } : null}
                        onSelect={onSelect}
                    />
                    <Button
                        disabled={!selectedEnvironment}
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onAssign}>
                        Save
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Override Environment?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to override the environment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

