import React from "react";

import {FunctionField} from "react-admin";
import {DeleteEventTypeAction} from "./DeleteEventTypeAction";

export const ActionsField = () => {
    return (
        <FunctionField
            label="actions"
            render={
                (record: any) => {
                    return (
                        <>
                            <DeleteEventTypeAction record={record}/>
                        </>
                    );
                }
            }
        />
    );
};
