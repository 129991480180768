import React, {useState} from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";

import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";
import {UserOptionType, UserPicker, UserTypeOptionType, UserTypePicker} from "../../Inputs";
import Typography from "@mui/material/Typography";

export type AddEventUserActionProps = {
    eventId: number;
}

export const AddEventUserAction = ({eventId}: AddEventUserActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedUserType, setSelectedUserType] = useState<UserTypeOptionType | null>(null);
    const [selectedUser, setSelectedUser] = useState<UserOptionType | null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const {mutateAsync: addUser, isLoading: isLoadingAddUser} = useMutation(() => {
        if (!selectedUserType || !selectedUser) {
            return;
        }
        return dataProvider.saPost(`Event/${eventId}/AddUser?UserId=${selectedUser.value}&UserType=${selectedUserType.value}`, {});
    }, {
        onSuccess: (data: { data: { code: number, message: string } }) => {
            if (data.data.code > 0) {
                notify(`User add failed - ${data.data.message}`, {type: 'error'});
            } else {
                notify("User added successfully", {type: 'success'});
            }
        },
        onError: () => {
            notify("User add failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries(`Event`);
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectUserType = (userType: UserTypeOptionType) => {
        setSelectedUserType(userType);
    }

    const onSelectUser = (user: UserOptionType) => {
        setSelectedUser(user);
    }

    const onAddUser = () => {
        setDialogIsOpen(true);
    }

    const addUserOp = async () => {
        if (!selectedUserType || !selectedUser) {
            notify("Please select a user and user type", {type: 'error'});
            return;
        }
        await addUser();
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await addUserOp();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingAddUser) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Tooltip title={`Add User`}>
                <Button size={'small'} onClick={handleOpen}>
                    <AddIcon/> Add User
                </Button>
            </Tooltip>
            <Popover
                id={String(eventId)}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 200,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="h6">Add User</Typography>
                    <UserPicker value={null} onSelect={onSelectUser}/>
                    <UserTypePicker
                        value={null}
                        onSelect={onSelectUserType}
                    />
                    <Button
                        disabled={!selectedUserType || !selectedUser}
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onAddUser}>
                        Save
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add User?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to add the user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

